import { initSwiperMount } from "shared/vanilla/initSwiperMount";
import { createApp, defineAsyncComponent } from 'vue';

import "./consumerTestimonials.scss";

const consumerTestimonialsBlockContainers = document.querySelectorAll(
	"[data-feature='consumerTestimonials']"
);

// Mount Vue components
consumerTestimonialsBlockContainers.forEach((consumerTestimonialsBlockContainer) => {
	const elements: NodeList = consumerTestimonialsBlockContainer.querySelectorAll(
		'[data-consumer-testimonials-content-block]'
	);
	if (elements.length) {
		elements.forEach((element) => {
			const htmlElement: HTMLElement = element as HTMLElement;

			const app = createApp({
				name: htmlElement?.dataset?.blockName,
			});

			app.component('consumer-testimonials-video-block', defineAsyncComponent(
				() => import('./consumerTestimonialsVideoBlock.vue')
			));

			app.mount(htmlElement);
		});
	}
});

initSwiperMount();